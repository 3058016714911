const titles = [
  {
    id: 'test-trace',
    name: 'Test & Trace',
    description: 'Real-time, validated test results for all residents.',
    metaTitle: 'COVID Test Result Delivery & Contact Tracing',
    metaDescription: '',
  },
  {
    id: 'test-vaccine-scheduling',
    name: 'Test & Vaccine Scheduling',
    description: 'Optimize scheduling for residents.',
    metaTitle: 'HIPAA Compliant Test & Vaccine Scheduling',
    metaDescription: 'Optimize test and vaccine scheduling for residents.',
  },
  {
    id: 'epi-management',
    name: 'Epi Management',
    description: 'Bring case management and real-time analysis to the cloud.',
    metaTitle: 'Epidemiological Management Software',
    metaDescription: '',
  },
  {
    id: 'health-records-data',
    name: 'Health Records & Data',
    description: 'Verified health records for every resident.',
    metaTitle: 'Immunization & Diagnostic Test Records',
    metaDescription: '',
  },
  {
    id: 'immunization-information-system',
    name: 'Immunization Information System',
    description: 'Improve scale, access and security with our cloud-based IIS.',
    metaTitle: '',
    metaDescription: '',
  },
  {
    id: 'nutritional-benefits',
    name: 'Nutritional Benefits',
    description: 'Increase enrollment, reduce churn and enable delivery.',
    metaTitle: 'WIC & SNAP Benefits Management',
    metaDescription: '',
  },
  {
    id: 'snap-benefits',
    name: 'SNAP Benefits',
    description: 'Increase enrollment, reduce churn and enable delivery.',
    metaTitle: 'SNAP Benefits Management',
    metaDescription: '',
  },
  {
    id: 'campus-health-pass',
    name: 'Campus Health Pass',
    description: 'Ensure the health & safety of students, faculty and staff.',
    metaTitle: 'Vaccine Passport for Universities',
    metaDescription: '',
  },
  {
    id: 'campus-testing',
    name: 'Campus Testing',
    description: 'Manage testing on campus seamlessly.',
    metaTitle: 'On-Campus Diagnostic Testing Software',
    metaDescription: '',
  },
  {
    id: 'event-health-pass',
    name: 'Event Health Pass',
    description: 'Ensure the health & safety of fans and employees.',
    metaTitle: 'Health Check for Events',
    metaDescription: '',
  },
  {
    id: 'employee-health-pass',
    name: 'Compliance Management',
    description: 'Ensure the health & safety of your employees.',
    metaTitle: '',
    metaDescription: '',
  },
  {
    id: 'employee-testing',
    name: 'Employee Testing',
    description: 'Manage on-site testing seamlessly.',
    metaTitle: 'COVID Testing Software for Businesses',
    metaDescription: '',
  },
  {
    id: 'medicaid',
    name: 'Medicaid Enrollment',
    description: 'Medicaid Benefits Enrollment',
    metaTitle: 'Medicaid Benefits Enrollment',
    metaDescription: '',
  },
  {
    id: 'behavioral-health',
    name: 'Behavioral Health',
    description: 'Case Management System',
    metaTitle: 'Behavioral Health',
    metaDescription: '',
  },
  {
    id: 'closed-loop-referral-system',
    name: 'Closed Loop Referral System',
    description: 'Referral Management',
    metaTitle: 'Closed Loop Referral System',
    metaDescription: '',
  },
  {
    id: 'on-site-testing',
    name: 'On-Site Testing',
    description: 'Testing for Schools & Businesses',
    metaTitle: 'On-Site Testing',
    metaDescription: '',
  },
];

export default {
  titles,
};

export { titles };
