<template>
  <div>
    <h3 class="section-subtitle">{{cardData.product_features_title}}</h3>
    <div class="features-container">
      <div class="features-wrapper"
          v-for="(item, index) in cardData.product_features_repeater"
          :key="index">
        <h2 class="features-title">{{item.feature_title }}</h2>
        <p class="features-description">{{item.feature_description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCta",
  props: {
    cardData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    openContactUs(){
      this.$zendesk.open();
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.features-container {
  display: flex;
  flex-direction: column;
  @include bp-medium {
    flex-direction: row;
  }
}
.features-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem 1rem;
  background: white;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  @include bp-mediumsmall {
    padding: 2rem 6.5rem;
    margin-bottom: 1rem;
  }
  @include bp-medium {
    padding: 2rem 1.5rem;
    margin: 0 0.5rem;
  }
  @include bp-large {
    padding: 4rem 3rem;
    margin: 0 0.75rem;
  }
}
.features-title {
  font-size: 2.25rem;
  line-height: 2.7rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  @include bp-mediumsmall {
    font-size: 3.25rem;
    line-height: 3.9rem;
  }
  @include bp-large {
    font-size: 4.25rem;
    line-height: 5.1rem;
  }
}
.features-description {
  font-size: 0.875rem;
  line-height: 1.225rem;
  font-weight: 500;
  margin-bottom: 0;
  @include bp-mediumsmall {
    font-size: 1.125rem;
    line-height: 1.463rem;
  }
  @include bp-medium {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  @include bp-large {
    font-size: 1.375rem;
    line-height: 1.788rem;
  }
}
</style>
