import { titles } from '@/constants/pageTitles';

const titlePrefix = process.env.VUE_APP_TITLE;
const metaDefaultDescription = process.env.VUE_APP_META_DESCRIPTION;

const setTitleAndMetaBySlug = function(slug) {
  let title = titles.find(x => x.id === slug);
  let titleName = title && title.metaTitle ?
    `${titlePrefix} | ${title.metaTitle}` :
    `${titlePrefix} | ${title.name}`;
  let metaDescription = title && title.metaDescription ?
    title.metaDescription :
    metaDefaultDescription;
  document.title = titleName;
  document.getElementsByTagName('meta')["description"].content = metaDescription;
};

const getNameBySlug = function(slug) {
  let title = titles.find(x => x.id === slug);
  return title ? title.name : "";
};

export {
  getNameBySlug,
  setTitleAndMetaBySlug
};
