<template>
  <div>
    <div class="headline-container">
      <div class="icon-container" v-if="slug">
        <img :src="headerIcon(slug)" :alt="slug" class="slug-icon" />
      </div>
      <div class="headline">
        {{ pageName }}
      </div>
    </div>
    <h2 class="section-title">{{ cardData.header_title }}</h2>
    <div class="media-wrapper">
      <div
        class="video-container"
        v-if="isVideoMediaType(cardData.header_media_type)"
        @mouseenter="toogleDisplayControls"
        @mouseleave="toogleDisplayControls"
      >
        <iframe
          :src="
            `${cardData.header_source_url}?h=dc8edb6321&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
          "
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreens
          title="Healthy Together"
          ref="vimeo"
          id="vimeo"
        ></iframe>
        <a
          class="mute-container"
          :class="{ hidden: !displayCustomControls }"
          @click="toggleMute"
        >
          <img :src="volumeControlIcon" alt="" />
        </a>
      </div>
      <div class="image-container" v-else>
        <img :src="cardData.header_source_url" alt="Image placeholder" />
      </div>
    </div>
    <div class="header-features-container">
      <div
        class="header-features-wrapper"
        v-for="(item, index) in cardData.header_features"
        :key="index"
      >
        <img
          class="header-features-check"
          :src="
            item.header_feature_icon ? item.header_feature_icon : CHECK_ICON
          "
          alt="icon check"
        />
        <h2 class="header-features-title">{{ item.header_feature_title }}</h2>
        <p class="header-features-description">
          {{ item.header_feature_description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Player from '@vimeo/player';
import { getNameBySlug } from '@/titleManager.js';
import { PRODUCT_PAGE_ICON_FOLDER } from '@/constants/cloudinary.js';

const CHECK_ICON =
  'https://res.cloudinary.com/healthytogether/image/upload/v1634740957/htio/product-pages/check_icon.png';
const ICON_VOLUME_ON =
  'https://res.cloudinary.com/healthytogether/image/upload/v1634853251/htio/product-pages/icon-sound-on.png';
const ICON_VOLUME_OFF =
  'https://res.cloudinary.com/healthytogether/image/upload/v1634853252/htio/product-pages/icon-sound-off.png';

export default {
  name: 'ProductHeader',
  props: {
    cardData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      headerHasVideo: false,
      playerMuted: false,
      displayCustomControls: false,
      player: null,
      slug: this.$route.params.slug,
      pageName: '',
    };
  },
  computed: {
    volumeControlIcon() {
      if (this.playerMuted) {
        return this.ICON_VOLUME_OFF;
      }
      return this.ICON_VOLUME_ON;
    },
  },
  watch: {
    headerHasVideo(newVal) {
      if (newVal) {
        this.generateVimeoPlayer();
      }
    },
    $route(newValue) {
      this.slug = newValue.params.slug;
      this.setPageName();
    },
  },
  created() {
    this.CHECK_ICON = CHECK_ICON;
    this.ICON_VOLUME_ON = ICON_VOLUME_ON;
    this.ICON_VOLUME_OFF = ICON_VOLUME_OFF;
    this.setPageName();
  },
  methods: {
    setPageName() {
      this.pageName = getNameBySlug(this.slug);
    },
    isVideoMediaType(type) {
      if (type === 'video') {
        this.headerHasVideo = true;
        return true;
      }
      return false;
    },
    generateVimeoPlayer() {
      const iframe = this.$refs.vimeo;
      this.player = new Player(iframe);
    },
    toggleMute() {
      const toggleMute = !this.playerMuted;
      this.player
        .setMuted(toggleMute)
        .then(muted => {
          this.playerMuted = muted;
        })
        .catch(function(error) {
          console.warn('enable to mute player: ', error);
        });
    },
    toogleDisplayControls() {
      this.displayCustomControls = !this.displayCustomControls;
    },
    headerIcon(icon) {
      return `${PRODUCT_PAGE_ICON_FOLDER}/icon-${icon}.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.media-wrapper {
  margin-bottom: 2.5rem;
}
.video-container {
  background: #f2f1f6;
  border-radius: 1.5rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(8, 35, 48, 0.1);
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
  }
}
.image-container {
  border-radius: 1.5rem;
  overflow: hidden;
  img {
    border-radius: 1.5rem;
  }
}
.mute-container {
  position: absolute;
  right: 2.5rem;
  bottom: 0.65rem;
  background: rgba(30, 30, 30, 0.9);
  cursor: pointer;
  text-align: center;
  color: white;
  width: 1.4rem;
  height: 1.356rem;
  border-radius: 4px;
  visibility: visible;
  opacity: 1;
  transition: all 0.2s linear;
  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;
  }
  &:hover {
    background: #0073ff;
  }
  img {
    width: auto;
  }
}
.header-features-check {
  width: 2rem;
  margin-bottom: 1rem;
  @include bp-large {
    width: 3rem;
  }
}
.header-features-container {
  display: flex;
  flex-direction: column;
  @include bp-medium {
    flex-direction: row;
  }
  @include bp-large {
    padding: 2rem 0 3rem;
  }
}
.header-features-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  @include bp-mediumsmall {
    padding: 2rem;
  }
}
.header-features-title {
  font-size: 1.125rem;
  line-height: 1.463rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  @include bp-mediumsmall {
    font-size: 1.5rem;
    line-height: 1.95rem;
  }
  @include bp-medium {
    font-size: 1.375rem;
    line-height: 1.788rem;
    margin-bottom: 1rem;
  }
  @include bp-large {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}
.header-features-description {
  font-size: 0.875rem;
  line-height: 1.225rem;
  font-weight: 400;
  margin-bottom: 1rem;
  @include bp-mediumsmall {
    font-size: 1.125rem;
    line-height: 1.463rem;
  }
  @include bp-medium {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 1rem;
  }
  @include bp-large {
    font-size: 1.375rem;
    line-height: 1.788rem;
  }
}
.headline-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.headline {
  line-height: 1.4rem;
  font-weight: 700;
  font-size: 1rem;
}
.icon-container {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 0.457rem;
  background: #f5f5f6;
  margin-right: 0.5rem;
  position: relative;
  .slug-icon {
    position: absolute;
    top: 23%;
    left: 22%;
    max-height: 1.225rem;
    width: auto;
  }
}
</style>
