<template>
  <div>
    <h3 class="section-subtitle">{{cardData.product_list_title}}</h3>
    <div class="card-container"
          :class="cardData.list_card_position">
      <div class="card-list-wrapper">
        <div class="list-item-container"
             v-for="(item, index) in cardData.list_items"
             :key="index">
          <img :src="cardData.list_items_icon_url" alt="list-item-icon">
          <div class="list-item">{{item.list_item_label}}</div>
        </div>
      </div>
      <div class="card-description">{{cardData.product_list_description }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProductListCards",
  props: {
    cardData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.card-container {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1.875rem;
  @include bp-medium {
    flex-direction: row;
    &.right {
      flex-direction: row-reverse;
      .card-description {
        margin-right: 1rem;
      }
    }
    &.left {
      .card-description {
        margin-left: 1rem;
      }
    }
  }
}
.card-image {
  border-radius: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  img {
    border-radius: 1rem;
  }
  @include bp-small {
    max-height: 15rem;
  }
  @include bp-medium {
    flex-basis: 30%;
    max-width: 14.313rem;
    margin-bottom: 0;
    border-radius: 1.5rem;
    max-height: unset;
    img {
      position: absolute;
      height: 100%;
      width: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @include bp-large {
    max-width: 22.688rem;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 1.5rem;
  padding: 2.625rem 1.5rem;
  @include bp-medium {
    flex: 1;
    padding: 3.188rem 2rem;
  }
  @include bp-large {
    padding: 4.938rem 2rem 3rem;
  }
}
.card-logo {
  img {
    border-radius: 1rem;
    height: 100%;
  }
}
.card-description {
  font-size: 1.125rem;
  line-height: 1.463rem;
  font-weight: 500;
  background: white;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 8rem 2rem;
  margin-bottom: 1rem;
  text-align: center;
  @include bp-xsmall {
    padding: 4rem 5rem;
  }
  @include bp-small {
    padding: 8rem 4rem;
  }
  @include bp-mediumsmall {
    font-size: 1.375rem;
    line-height: 1.788rem;
    padding: 8rem 4rem;
  }
  @include bp-medium {
    width: 60%;
    padding: 0 4rem 0 2.5rem;
    text-align: left;
    margin-bottom: 0;
  }
  @include bp-large {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}
.card-list-wrapper {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 6rem 4.625rem;
  border-radius: 1rem;
  @include bp-xsmall {
    padding: 4rem 2rem;
  }
  @include bp-small {
    padding: 6rem 4rem;
  }
}

.list-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
  @include bp-xsmall {
    margin-bottom: 1rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 2rem;
    margin-right: 1rem;
  }
}
.section-subtitle {
  margin: 0 auto 3.5rem;
  max-width: 65%;
  text-align: center;
}
</style>
