<template>
  <div class="products-container">
    <div
      class="product-card"
      :class="`${name}_container`"
      v-for="(field, name, index) in page.fields"
      :key="index"
    >
      <!-- Header Section -->
      <product-header
        :class="`${name}_wrapper`"
        v-if="shouldDisplayHeader(field, name)"
        :cardData="field"
      />
      <!-- Features Section -->
      <product-features
        :class="`${name}_wrapper`"
        v-if="shouldDisplayFeatures(field, name)"
        :cardData="field"
      />
      <!-- Cards Section -->
      <product-cards
        :class="`${name}_wrapper`"
        v-if="shouldDisplayCards(field, name)"
        :cardData="field"
      />
      <!-- Highlights Section -->
      <product-highlights
        :class="`${name}_wrapper`"
        v-if="shouldDisplayHighlights(field, name)"
        :cardData="field"
      />
      <!-- Highlights Section -->
      <product-list-cards
        :class="`${name}_wrapper`"
        v-if="shouldDisplayListCards(field, name)"
        :cardData="field"
      />
      <!-- CTA Button Section -->
      <product-cta
        :class="`${name}_wrapper`"
        v-if="shouldDisplayCta(field, name)"
        :cardData="field"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { butter } from '@/buttercms';
import ProductHeader from '@/components/basic/ProductHeader';
import ProductFeatures from '@/components/basic/ProductFeatures';
import ProductCards from '@/components/basic/ProductCards';
import ProductHighlights from '../components/basic/ProductHighlights.vue';
import ProductListCards from '../components/basic/ProductListCards.vue';
import ProductCta from '@/components/basic/ProductCta';
import { setTitleAndMetaBySlug } from '@/titleManager.js';

export default {
  name: 'products',
  components: {
    ProductHeader,
    ProductFeatures,
    ProductCards,
    ProductHighlights,
    ProductListCards,
    ProductCta,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      title: 'products',
      page: {
        slug: '',
        fields: {},
        player: null,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      to.params.slug === 'employee-testing' ||
      to.params.slug === 'campus-testing'
    ) {
      next({
        name: 'product',
        params: { locale: to.params.locale, slug: 'on-site-testing' },
      });
    }
    next();
  },
  computed: {
    ...mapState(['showPreview']),
    locale() {
      return this.$route.params.locale;
    },
  },
  watch: {
    $route(newValue) {
      this.slug = newValue.params.slug;
      setTitleAndMetaBySlug(this.slug);
      this.fetchPage();
    },
  },
  methods: {
    fetchPage() {
      butter.page
        .retrieve('*', this.slug, {
          locale: this.locale,
          preview: this.showPreview ? 1 : 0,
        })
        .then(resp => {
          this.page = resp.data.data;
        })
        .catch(e => {
          console.warn(e);
          this.$router.push({ name: 'home' });
        });
    },
    shouldDisplayHeader(field, name) {
      if (name === 'product_header' && field.header_title) return true;
      return false;
    },
    shouldDisplayCards(field, name) {
      if (name === 'product_cards' && field.product_cards_repeater.length)
        return true;
      return false;
    },
    shouldDisplayHighlights(field, name) {
      if (
        name === 'product_highlights' &&
        field.product_highlights_cards.length
      )
        return true;
      return false;
    },
    shouldDisplayListCards(field, name) {
      if (name === 'product_list' && field.list_items.length) return true;
      return false;
    },
    shouldDisplayFeatures(field, name) {
      if (name === 'product_features' && field.product_features_repeater.length)
        return true;
      return false;
    },
    shouldDisplayCta(field, name) {
      if (name === 'product_cta' && field.cta_text.length) return true;
      return false;
    },
  },
  created() {
    try {
      setTitleAndMetaBySlug(this.slug);
      this.fetchPage();
    } catch (err) {
      this.$router.push('/');
    }
  },
};
</script>

<style lang="scss">
.content.product {
  min-height: auto;
  padding: 0;
  background: #f2f1f6;
  word-break: break-word;
}
.product_header_container {
  background: #ffffff;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.section-title {
  font-size: 1.5rem;
  line-height: 1.95rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  @include bp-mediumsmall {
    padding: 0 2rem;
    font-size: 1.75rem;
    line-height: 2.1rem;
  }
  @include bp-medium {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
  @include bp-large {
    font-size: 3.25rem;
    line-height: 3.9rem;
    margin-bottom: 2.5rem;
    padding: 0 14rem;
  }
}
.section-subtitle {
  font-size: 1.125rem;
  line-height: 1.463rem;
  font-weight: 700;
  margin: 0 1.5rem 1rem;
  @include bp-mediumsmall {
    font-size: 1.5rem;
    line-height: 1.95rem;
  }
  @include bp-medium {
    font-size: 1.375rem;
    line-height: 1.788rem;
    margin-bottom: 1rem;
  }
  @include bp-large {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
</style>

<style scoped lang="scss">
@import '../styles/global/marketing.scss';

// header
.product_header_wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 2rem;
  @include bp-small {
    padding: 2rem 1.5rem 1.25rem;
  }
  @include bp-medium {
    padding: 2.5rem 3rem 1.25rem;
  }
  @include bp-large {
    padding: 6.5rem $responsive-padding-desktop 1.25rem;
  }
}
// features
.product_features_wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @include bp-small {
    padding: 2rem 1.5rem 1.25rem;
  }
  @include bp-medium {
    padding: 2.5rem 1rem 1.25rem;
  }
  @include bp-large {
    padding: 6.5rem $responsive-padding-desktop 1.25rem;
  }
}
// cards
.product_cards_wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @include bp-small {
    padding: 2rem 1.5rem 0;
  }
  @include bp-medium {
    padding: 2.5rem 1.5rem 0;
  }
  @include bp-large {
    padding: 6.5rem $responsive-padding-desktop 0;
  }
}
// highlights
.product_highlights_wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @include bp-small {
    padding: 2rem 1.5rem 0;
  }
  @include bp-medium {
    padding: 2.5rem 1.5rem 0;
  }
  @include bp-large {
    padding: 6.5rem $responsive-padding-desktop 0;
  }
}
// list-cards
.product_list_wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @include bp-small {
    padding: 2rem 1.5rem 0;
  }
  @include bp-medium {
    padding: 2.5rem 1.5rem 0;
  }
  @include bp-large {
    padding: 6.5rem $responsive-padding-desktop 0;
  }
}
// cta section
.product_cta_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.125rem 0;
  @include bp-small {
    padding: 6.25rem 0;
  }
  @include bp-medium {
    padding: 10.625rem 0;
  }
  @include bp-large {
    padding: 10.625rem $responsive-padding-desktop;
  }
}
</style>
